import './Report.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, Input, Label, Persona, Spinner, Textarea, Tooltip, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { history } from '../../_helpers';
import { ArrowLeftFilled, ChatFilled, ChatRegular, DocumentFilled, DocumentRegular, InfoRegular, bundleIcon } from '@fluentui/react-icons';
import { Icon, IconButton } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import Moment from 'moment';
import { userService } from '../../_services/user.services';

const throttle = require('lodash.throttle');

function Report(props:any) {

  const params = useParams();

  const [itemAttachments, setItemAttachments]:any[] = useState([]);
  const [attachments, setAttachments]:any[] = useState([]);
  const [showReply, setShowReply] = useState(false);
  const [message, setMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [report, setReport]:any = useState(null);
  const [loadingReport, setLoadingReport]:any = useState(true);
  const [errorMessage, setErrorMessage]:any = useState("");
  const [messages, setMessages]:any[] = useState([]);
  const [loadingMessages, setLoadingMessages]:any[] = useState(true);
  const [page, setPage]:any[] = useState(0);
  const [alertDialog, setAlertDialog]:any = useState({opened: false})
  const [canLoadMoreMessages, setCanLoadMoreMessages]:any[] = useState(false);
  const [subscribed, setSubscribed]:any[] = useState(false);
  const [subscriptionEmailDialog, setSubscriptionEmailDialog]:any[] = useState(false);
  const [subscriptionEmail, setSubscriptionEmail]:any[] = useState('');


  const ChatIcon = bundleIcon(ChatFilled, ChatRegular);
  const DocumentIcon = bundleIcon(DocumentFilled, DocumentRegular);
  const ArrowLeft = bundleIcon(ArrowLeftFilled, ArrowLeftFilled);

  var loadingMore = false;

  const onFileDrop = (event:any, id:string) => {
    event.stopPropagation();
    event.preventDefault();
  
    event.target.style.background = "transparent";
  
    if(event.dataTransfer.files.length > 0) {
      const files:File[] = Array.from(event.dataTransfer.files)
      let newAttachments = attachments.slice(0)
      for (let i in files) {
        if (files[i] && files[i].size >= 25000000) {
          alert("Maximum attachment allowed size: 25 Mb.")
        } else {
          newAttachments.push({name: files[i].name , file: files[i]})
        }
      }
      setAttachments(newAttachments)
    }
  }

  const _showFilePicker = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.multiple = true

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();
      const files:File[] = Array.from(event.target.files)
      let newAttachments = attachments.slice(0)
      if(files.length > 0) {
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            alert("Maximum attachment allowed size: 25 Mb.")
          } else {
            newAttachments.push({name: files[i].name , file: files[i]})
          }
        }
        setAttachments(newAttachments)
      }
    }

    input.click();
  }

  const _submit = (e:any) => {
    e.preventDefault();
    history.push('/'+params.id+'/report/test')
  }

  const _sendMessage = () => {
    if (message && message.length > 0) {
      setSendingMessage(true)

      let promises:any[] = [];
      let attachmentIds:string[] = [];
      let hasError = false;
      for (let i in attachments) {
        const dropFormData = new FormData();
        dropFormData.append('attachment', attachments[i].file, attachments[i].file.name);
        promises.push(userService.submitAttachment(params.id, dropFormData).then((response)=>{
          attachmentIds.push(response.data.id)
        }).catch((error)=>{
          console.error(error)
          setSendingMessage(false)
          hasError = true
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorSubmittingAttachment')
          })
        }))
      }

      Promise.all(promises).then(()=>{
        if (!hasError) {
          const data = {
            content: message,
            internal: false,
            attachmentIds: attachmentIds
          }
          userService.submitMessage(report.id, data).then((response) => {
            setMessage("");
            setAttachments([]);
            setSendingMessage(false)
            setShowReply(false);
            let newMessages = messages;
            newMessages.push(response.data)
            setMessages(newMessages)
          }).catch((error)=>{
            console.error(error)
            setSendingMessage(false)
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorSubmittingMessage')
            })
          })
        }
      })
    }
  }

  const onPaste = (e:any, id:string) => {
    if (!e.clipboardData.files.length) {
      return;
    } else {
      e.preventDefault()
    }
    const files:File[] =  Array.from(e.clipboardData.files)
    let newAttachments = attachments.slice(0)
    if(files.length > 0) {
      for (let i in files) {
        if (files[i] && files[i].size >= 25000000) {
          alert("Maximum attachment allowed size: 25 Mb.")
        } else {
          newAttachments.push({name: files[i].name , file: files[i]})
        }
      }
      setAttachments(newAttachments)
    }
  }
  
  const _getContactName = (id: string) => {
    if (id) {
      if (props.company && props.company.id === id) return props.company.name;
      return props.company.name;
    } else if (report.author) {
      return report.author.name + ' ' + report.author.surname
    } else return i18n.t('app:anonymous')
  }

  const _downloadAttachment = (item:any) => {
    userService.getAttachment(report.id, item.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = item.name;
  
      document.body.appendChild(anchorElem);
      anchorElem.click();
  
      document.body.removeChild(anchorElem);
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
    })
  }

  const _playAttachment = (attachment:any) => {
    let attachments:any[] = itemAttachments.slice(0)
    for (let i in attachments) {
      if (attachments[i].id === attachment.id) attachments[i].downloading = true
    }
    setItemAttachments(attachments)
    userService.getAttachment(report.id, attachment.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      attachment.file = url
      let attachments:any[] = itemAttachments.slice(0)
      for (let i in attachments) {
        if (attachments[i].id === attachment.id) {
          attachments[i].file = url
          attachments[i].downloading = false
        }
      }
      setItemAttachments(attachments)
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
      let attachments:any[] = itemAttachments.slice(0)
      for (let i in attachments) {
        if (attachments[i].id === attachment.id) {
          attachments[i].downloading = false
        }
      }
      setItemAttachments(attachments)
    })
  }

  const _downloadMessageAttachment = (message:any, item:any) => {
    userService.getMessageAttachment(report.id, message.id, item.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = item.name;
  
      document.body.appendChild(anchorElem);
      anchorElem.click();
  
      document.body.removeChild(anchorElem);
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
    })
  }

  const loadMessages = (reportId, page) => {
    loadingMore = true;
    setLoadingMessages(true)
    userService.getMessages(reportId, page).then((response) => {
      if (response.data.last) setCanLoadMoreMessages(false);
      else setCanLoadMoreMessages(true);
      setMessages([...messages, ...response.data.content])
      setPage(page+1)
      setLoadingMessages(false)
      loadingMore = false;
    }).catch((error) => {
      console.error(error)
      loadingMore = false;
      setLoadingMessages(false)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorFetchingMessages')
      })
    })
  }

  const getCompany = () => {
    userService.getCompanyPage(params.id).then((response) => {
      var tinycolor = require("tinycolor2");
      const primaryColor = response.data.color || '#0f6cbd';
      const hoverColor = tinycolor(primaryColor).darken(4).toString();
      const pressColor = tinycolor(primaryColor).darken(8).toString();
      props.setCompany(response.data)
      props.setCustomLightTheme({
        ...webLightTheme,
        colorBrandBackground: primaryColor,
        colorBrandBackgroundHover: hoverColor,
        colorBrandBackgroundPressed: pressColor,
        colorCompoundBrandBackground: primaryColor,
        colorCompoundBrandBackgroundHover: hoverColor,
        colorCompoundBrandBackgroundPressed: pressColor,
        colorCompoundBrandStroke: primaryColor,
        colorCompoundBrandStrokeHover: hoverColor,
        colorCompoundBrandStrokePressed: pressColor,
        colorNeutralForeground2BrandHover: primaryColor,
        colorNeutralStrokeAccessible: '#d1d1d1',
        colorNeutralStrokeAccessibleHover: '#d1d1d1',
        fontSizeBase300: '16px'
      })
    }).catch((error)=>{
      props.setCustomLightTheme({
        ...webLightTheme,
        fontSizeBase300: '16px'
      })
      console.error(error)
      if (error && error.response && error.response.status === 404) {
        history.push('/')
      }
    })
  }

  const handleScroll = throttle((e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
    if (bottom) {
      if (!loadingMore && canLoadMoreMessages && report) loadMessages(report.id, page)
    }
  },100)

  const _handleCheck = (e,data) => {
    setSubscriptionEmail('')
    if (data.checked) {
      setSubscriptionEmailDialog(true)
    } else {
      userService.subscribeReport(report.id, null).then(()=>{
        setSubscribed(false)
      }).catch((error) => {
        console.error(error)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUnsubscribing')
        })
      })
    }
  }

  const _subscribe = (e) => {
    e.preventDefault();
    userService.subscribeReport(report.id, subscriptionEmail).then((response)=>{
      setSubscribed(true)
      setSubscriptionEmailDialog(false)
    }).catch((error) => {
      console.error(error)
      setSubscriptionEmailDialog(false)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorSubscribing')
      })
    })
  } 

  useEffect(()=>{
    if(!props.company) getCompany();
  }, [])

  useEffect(()=>{
    userService.getReport().then((response)=>{
      setReport({...response.data, shortId: response.data.id.split('-').pop()})
      setItemAttachments(response.data.attachments ?? [])
      setSubscribed(response.data.subscription)
      loadingMore = true
      userService.getMessages(response.data.id, 0).then((response) => {
        if (response.data.last) setCanLoadMoreMessages(false);
        else setCanLoadMoreMessages(true);
        setMessages(response.data.content)
        setPage(page+1)
        setLoadingMessages(false)
        setLoadingReport(false)
        loadingMore = false
      }).catch((error) => {
        console.error(error)
        loadingMore = false
        setLoadingMessages(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorFetchingMessages')
        })
      })
    }).catch((error) => {
      console.error(error)
      loadingMore = false
      setLoadingReport(false)
      if (error && error.response && error.response.status === 404) {
        setErrorMessage(i18n.t('app:noReport'))
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:noReport')
        })
      } else {
        setErrorMessage(i18n.t('app:errorLoadingReport'))
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorLoadingReport')
        })
      }
    })
    //document.getElementById('main-view')?.addEventListener("scroll", handleInfiniteScroll);
    //return (()=>{
    //  document.getElementById('main-view')?.removeEventListener("scroll", handleInfiniteScroll, false);
    //})
  }, [])

  const labelWidth = i18n.language === 'es' ? '160px' : '100px' 

  return (
    <div className='h-100'>
      <Titlebar/>
      <div id='main-view' className='page-base' onScroll={handleScroll}>
      <form onSubmit={_submit} className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            {props.company && props.company.image && <Image className='company-logo-large m-auto' src={props.company ? 'data:image/png;base64,' + props.company.image : ''}/>}
          </div>
          <Divider className='my-3'/>
          <Link to={'/'+params.id}><Button className='px-0' icon={<ArrowLeft/>} appearance='transparent' >{i18n.t('app:backToHomepage')}</Button></Link>

          {report && !loadingReport ? <>
          <div className='d-flex align-items-center justify-content-center mb-4 mt-3'>
            <h2 className='title m-0 me-2 '>{i18n.t('app:report')}</h2>
            <h2 className='title m-0' style={{color: 'grey'}}>{' #' + report?.shortId}</h2>
          </div>
          <div className='d-flex flex-wrap'>
            <div className='d-flex align-items-center mb-3 me-2'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:reported')}: </span>
                <span>{Moment(report.createdDateTime).format('Y-MM-DD')}</span>
              </div>
            </div>
          </div>
          <div className='d-flex flex-wrap'>
            <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:lastUpdated')}: </span>
                <span>{Moment(report.modifiedDateTime).format('Y-MM-DD')}</span>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:subject')}: </span>
              <span>{report.subject}</span>
            </div>
          </div>
          {report.category && <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:category')}: </span>
              <span>{report.category}</span>
            </div>
          </div>}
          {report && report.author && <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center mb-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:reportedBy')}: </span>
              <Tooltip 
                content={
                  <div className='d-flex align-items-center'>
                    <Persona className="me-2" name={report.author.name + ' ' + report.author.surname} size='small' primaryText="" avatar={{ color: "colorful", style: { margin: 0 } }} />
                    <div>
                      <span>{report.author.name + ' ' + report.author.surname}</span>
                      <div>
                        <a style={{ color: '#0f548c' }} href={'mail:' + report.author.email}>{report.author.email}</a>
                      </div>
                    </div>
                  </div>}
                relationship={'label'}
              >
                <Persona name={report.author.name + ' ' + report.author.surname} size='extra-small' avatar={{ color: "colorful" }} />
              </Tooltip>
            </div>
          </div>}
          <div className='mb-3'>
            <div className='d-flex' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'nowrap'}}>
              <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:details')}:</span>
              <div className='' style={{overflowWrap: 'normal', whiteSpace: 'break-spaces'}}>
                <span>{report.description ? report.description.trim() : ''}</span>
              </div>
            </div>
          </div>
          {itemAttachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
            <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
            <span className='me-2' title={item.name}>{item.name}</span>
            { item.name.split('.').pop() === 'wav' ? <div className='ms-auto d-flex' style={{height: '30px', minWidth: '50px'}}>
            {item.downloading ? <Spinner size='tiny' style={{height: '30px', width: '30px'}}/> : !item.file ? <Tooltip content={i18n.t('app:play') || ''} relationship={'label'}><div><IconButton className='' iconProps={{iconName: 'Play'}} style={{height: '30px', width: '30px'}} onClick={(e)=>{
              _playAttachment(item)
              e.stopPropagation();
            }}/></div></Tooltip> : null}
            {item.file && <audio className='h-100 py-1' controls>
              <source src={item.file} type="audio/wav"/>
            </audio>}
            <Tooltip content={i18n.t('app:download') || ''} relationship={'label'}>
              <div>
                <IconButton className='' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                  _downloadAttachment(item)
                  e.stopPropagation();
                }}/>
              </div>
            </Tooltip>
            </div>
            :
            <IconButton className='ms-auto' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
              _downloadAttachment(item)
              e.stopPropagation();
            }}/>
            }
          </div>})}
          <div className='mt-3'><Checkbox checked={subscribed} onChange={_handleCheck} style={{left: '-8px', position: 'relative'}} label={i18n.t('app:subscribe')}/></div>
          <div className='mt-3'>
            <Divider className='my-3'/>
            <div className='d-flex pb-2'>
              <h2 className='title-xsmall m-0'><ChatIcon className='me-2' style={{position: 'relative', top: '-3px'}}/>{i18n.t('app:messages')}</h2>
            </div>

            <div id='main-view-conversation' className='messages-box'>
              {messages.map((message: any) => (
                <div key={message.id} id={message.id} className='message-wrapper d-flex'>
                  <div className='vertical-hr'></div>
                  {message.createdByUserId ? <Persona className="me-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                  <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                    <div className = "message-top d-flex w-100" style={{backgroundColor: !message.createdByUserId ? '#C7E0F4' : ''}}>
                      <div className='author d-flex align-items-center'>
                        {_getContactName(message.createdByUserId)}
                        <span className='ms-1'>{' on ' + Moment(message.createdDateTime).format('Y-MMM-D')}</span>
                      </div>
                    </div>
                    <div className='message-content'>
                      {message.attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                        {message.attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
                          <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                          <span className='me-2' title={item.name}>{item.name}</span>
                          <IconButton className='ms-auto' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                            _downloadMessageAttachment(message, item)
                            e.stopPropagation();
                          }}/>
                        </div>})}
                      </div>}
                      <div className='mt-2' style={{whiteSpace: 'pre-wrap'}} >
                        {message.content || ''}
                      </div>
                    </div>
                    <div className = "message-bottom d-flex w-100">
                      
                    </div>
                  </div>
                  {!message.createdByUserId ? <Persona className="ms-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                </div>
              ))}
            </div>
            {loadingMessages && <Spinner className='m-auto' size='extra-small'/>}
            {messages.length > 0 && <hr style={{borderColor: '#bfbfbf', margin: 0}}/>}
            {<div className='message-wrapper d-flex'>
              <div style={{width: '36px'}}/>
              {!showReply ?
              <Input className='flex-grow-1' placeholder={i18n.t('app:writeReply')+'...'} onClick={()=>{setShowReply(true)}} style={{marginTop: '2px'}}/>
              :
              <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                <div className = "message-top d-flex py-0 align-items-center" style={{backgroundColor: '#C7E0F4', height: '33px'}}>
                  <span className='author ms-1'>
                    {i18n.t('app:reply')}
                  </span>
                  <IconButton iconProps={{iconName: 'Attach'}} onClick={()=>{_showFilePicker()}} className='ms-auto normal-button-opacity' style={{height: '27px', color: 'rgb(16, 110, 190)'}}/>
                </div>
                <div className='message-content mt-2'>
                  {attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                    {attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
                      <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.')[item.name.split('.').length -1], size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                      <span className='me-2' title={item.name}>{item.name}</span>
                      <IconButton iconProps={{iconName: 'Cancel'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                        e.stopPropagation();
                        setAttachments(attachments.filter((a:any)=>{return a.name !== item.name}))
                      }}/>
                    </div>})}
                  </div>}
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <div className='flex-grow-1' style={{display: 'flex'}}>
                      <Textarea
                        id="response-textbox"
                        onDragEnter={onDragEnter} 
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver} 
                        onDrop={(e) => onFileDrop(e, 'response-textbox')}
                        onPaste={(e)=> onPaste(e, "response-textbox")}
                        className='flex-grow-1 w-100'
                        placeholder={i18n.t('app:writeReply')+'...'}
                        value={message}
                        textarea={{style:{minHeight: '100px'}}}
                        onChange={(e,data)=>{setMessage(data.value)}}
                        resize={'vertical'}
                        style={{borderColor: 'rgb(230,230,230)'}}
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <Button disabled={sendingMessage} className='ms-auto cancel-button' onClick={()=>{setShowReply(false);setAttachments([]);setMessage("")}}>{i18n.t('app:cancel')}</Button>
                    <Button disabled={sendingMessage || !message} appearance='primary'  className='ms-3' onClick={()=>{_sendMessage()}}>{sendingMessage ? <Spinner size='tiny'/> : i18n.t('app:send')}</Button>
                  </div>
                </div>
              </div>}
              <Persona className="ms-2" style={{marginTop: '3px'}} name={_getContactName('')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            </div>}
          </div>
          </> : loadingReport ? <Spinner className='m-auto' size='extra-small'/> : <div className='text-center'>{errorMessage}</div>}

        </div>
      </form>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Dialog open={subscriptionEmailDialog}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{i18n.t('app:subscribe')}</DialogTitle>
            <DialogContent>
              <form onSubmit={_subscribe}>
                <Label className='mb-1' weight='semibold'>{i18n.t('app:email')}</Label>
                <Input className='w-100' onSubmit={_subscribe} value={subscriptionEmail} onChange={(e,data)=>{setSubscriptionEmail(data.value)}} placeholder={i18n.t('app:enterEmail') || ''}></Input>
                <div className='d-flex align-items-center mt-2 mb-1' style={{opacity: .8}}><InfoRegular className='me-1'/><span>{i18n.t('app:emailWillNotBeVisible')}</span></div>
              </form>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setSubscriptionEmailDialog(false)}}>{i18n.t('app:cancel')}</Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={_subscribe} appearance="primary">{i18n.t('app:save')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

const onDragEnter = (event: any) => {
  event.target.style.background = "#C7E0F4";
}

const onDragLeave = (event: any) => {
  event.target.style.background = "transparent";
}

const onDragOver = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
}

export default Report;