import './CheckReport.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, Input, Label, Spinner, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { Link, useParams } from 'react-router-dom';
import { history } from '../../_helpers';
import { useEffect, useState } from 'react';
import { ArrowLeftFilled, bundleIcon } from '@fluentui/react-icons';
import { userService } from '../../_services/user.services';

function CheckReport(props:any) {

  const params = useParams();

  const [reportCode, setReportCode] = useState("");
  const [loadingReport, setLoadingReport] = useState(false);
  const [alertDialog, setAlertDialog]:any = useState({opened: false})

  const ArrowLeft = bundleIcon(ArrowLeftFilled, ArrowLeftFilled);

  
  const _submit = (e:any) => {
    e.preventDefault();
    if (!loadingReport) {
      setLoadingReport(true)

      const data = {
        organizationCode: params.id,
        reportCode: reportCode
      }

      userService.getReportToken(data).then((response) => {
        setLoadingReport(false)
        sessionStorage.setItem('token', response.data.token)
        history.push('/'+params.id+'/report')
      }).catch((error)=>{
        setLoadingReport(false)
        console.error(error)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorLoadingReport')
        })
      })
    }
  }

  const getCompany = () => {
    userService.getCompanyPage(params.id).then((response) => {
      var tinycolor = require("tinycolor2");
      const primaryColor = response.data.color || '#0f6cbd';
      const hoverColor = tinycolor(primaryColor).darken(4).toString();
      const pressColor = tinycolor(primaryColor).darken(8).toString();
      props.setCompany(response.data)
      props.setCustomLightTheme({
        ...webLightTheme,
        colorBrandBackground: primaryColor,
        colorBrandBackgroundHover: hoverColor,
        colorBrandBackgroundPressed: pressColor,
        colorCompoundBrandBackground: primaryColor,
        colorCompoundBrandBackgroundHover: hoverColor,
        colorCompoundBrandBackgroundPressed: pressColor,
        colorCompoundBrandStroke: primaryColor,
        colorCompoundBrandStrokeHover: hoverColor,
        colorCompoundBrandStrokePressed: pressColor,
        colorNeutralForeground2BrandHover: primaryColor,
        colorNeutralStrokeAccessible: '#d1d1d1',
        colorNeutralStrokeAccessibleHover: '#d1d1d1',
        fontSizeBase300: '16px'
      })
    }).catch((error)=>{
      props.setCustomLightTheme({
        ...webLightTheme,
        fontSizeBase300: '16px'
      })
      console.error(error)
      if (error && error.response && error.response.status === 404) {
        history.push('/')
      }
    })
  }

  useEffect(()=>{
    if(!props.company) getCompany()
  }, [])

  return (
    <div className='h-100'>
      <Titlebar/>
      <div className='page-base'>
      <form onSubmit={_submit} className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            {props.company && props.company.image && <Image className='company-logo-large m-auto' src={props.company ? 'data:image/png;base64,' + props.company.image : ''}/>}
          </div>
          <Divider className='my-3'/>
          <Link to={'/'+params.id}><Button className='px-0' icon={<ArrowLeft/>} appearance='transparent' >{i18n.t('app:backToHomepage')}</Button></Link>
          <h2 className='title-small m-0 pb-2 text-center'>{i18n.t('app:checkReport')}</h2>
          <div>
            <div>
              <Label weight='semibold'>{i18n.t('app:reportCode')}</Label>
              <div>
                <Input value={reportCode} onChange={(e, data:any) => setReportCode(data.value)} required className='w-100 mt-2'/>
              </div>
            </div>
            <div className='d-flex w-100 ms-auto align-items-center justify-content-center mt-3'>
              <Button disabled={loadingReport} type='submit' className='w-100' style={{maxWidth: '200px'}} appearance='primary'>{loadingReport ? <Spinner size='tiny'/> : i18n.t('app:continue')}</Button>
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-center'>
          </div>
        </div>
      </form>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default CheckReport;