import axios from 'axios'
import i18n from "i18next";
import { history } from '../_helpers/history';

function getStorage(name: string) {
    var cookie = ''
    return cookie
}

const synergyApi = "https://whistleblowing-api."//process.env.REACT_APP_SYNERGY_API_URL
var serverDomain = "eu-prod.synergyos.com";
var membershipApi = synergyApi + serverDomain + '/membership';
var governanceApi = synergyApi + serverDomain + '/governance';
const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : 'localhost';

function submitReport(requestOptions) {
    return axios.post(governanceApi + '/public/reports', requestOptions).then(response => {
            return response
        }).catch(error => {
            console.log(error)
            throw error;
        });
}

function getReportToken(requestOptions) {
    return axios.post(membershipApi + '/public/auth/generate-token', requestOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getReport() {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') }
    };

    return axios.get(governanceApi + '/externals/reports', headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function submitAttachment(organizationCode, requestOptions) {
    const headersOptions:any = {
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    };

    return axios.post(governanceApi + `/public/attachments?organizationCode=${organizationCode}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getAttachment(reportId, attachmentId) {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') },
        responseType: "blob"
    };

    return axios.get(governanceApi + `/externals/${reportId}/attachments/${attachmentId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getMessages(reportId, page) {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') }
    };

    return axios.get(governanceApi + `/externals/${reportId}/messages?page=${page}&size=50`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getMessageAttachment(reportId, messageId, attachmentId) {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') },
        responseType: "blob"
    };

    return axios.get(governanceApi + `/externals/${reportId}/messages/${messageId}/attachments/${attachmentId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function submitMessage(reportId, requestOptions) {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') }
    };

    return axios.post(governanceApi + `/externals/${reportId}/messages`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getCompanyPage(code) {
    return axios.get(membershipApi + `/public/organizations?code=${code}`).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function subscribeReport(reportId, email) {
    const headersOptions:any = {
        headers: { 'Authorization': sessionStorage.getItem('token') }
    };

    const data = {
        email: email
    }

    return axios.post(governanceApi + `/externals/${reportId}/subscription`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function handleResponse(response:any) {
    if(response) {
        if (response.status === 403) {
            history.push('/login');
            throw response;
        } else if (response.status === 405) {
            alert(i18n.t('app:noPermission'))
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            throw response;
        } else {
            throw response;
        }
    }
    
    if(!response) {
        return 'Error: Network Error';
    }
}

export const userService = {
    submitReport,
    getReportToken,
    getReport,
    submitAttachment,
    getAttachment,
    getMessages,
    getMessageAttachment,
    submitMessage,
    getCompanyPage,
    subscribeReport
}