import './NotFound.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, Input, Label, Spinner, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { history } from '../../_helpers';
import { useEffect, useState } from 'react';
import { userService } from '../../_services/user.services';

function NotFound(props:any) {

  const [companyCode, setCompanyCode] = useState("");
  const [loadingReport, setLoadingReport] = useState(false);
  const [alertDialog, setAlertDialog]:any = useState({opened: false})

  const _submit = (e:any) => {
    e.preventDefault();
    if (!loadingReport) {
      setLoadingReport(true)
      userService.getCompanyPage(companyCode).then((response) => {
        setLoadingReport(false)
        history.push('/'+companyCode)
      }).catch((error)=>{
        console.error(error)
        setLoadingReport(false)
        if (error && error.response && error.response.status === 404) {
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:noOrganization')
          })
        } else {
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingPage')
          })
        }
      })
    }
  }

  useEffect(()=>{
    props.setCustomLightTheme && props.setCustomLightTheme({
      ...webLightTheme,
      fontSizeBase300: '16px'
    })
  },[])

  return (
    <div className='h-100'>
      <Titlebar/>
      <div className='page-base'>
      <form onSubmit={_submit} className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            <Image className='company-logo-large m-auto' src="/img/synergy_logo_colour.png"/>
          </div>
          <Divider className='my-3'/>
          <div>
            <div>
              <Label weight='semibold'>{i18n.t('app:companyCode')}</Label>
              <div>
                <Input value={companyCode} onChange={(e, data:any) => setCompanyCode(data.value)} required className='w-100 mt-2'/>
              </div>
            </div>
            <div className='d-flex w-100 ms-auto align-items-center justify-content-center mt-3'>
              <Button disabled={loadingReport} type='submit' className='w-100' style={{maxWidth: '200px'}} appearance='primary'>{loadingReport ? <Spinner size='tiny'/> : i18n.t('app:continue')}</Button>
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-center'>
          </div>
        </div>
      </form>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false});history.push('/')}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default NotFound;