import './Main.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, Text, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { useParams } from 'react-router-dom';
import { history } from '../../_helpers';
import { AddRegular, MailInboxRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { userService } from '../../_services/user.services';

function Main(props:any) {

  const params = useParams();
  const [logo, setLogo]:any = useState();
  const [title, setTitle]:any = useState();
  const [markdown, setMarkdown]:any = useState();
  const [alertDialog, setAlertDialog]:any = useState({opened: false})

  if (!params || !params.id ) {
    history.push('/')
  }

  const setCompanyData = (data) => {
    var tinycolor = require("tinycolor2");
    const primaryColor = data.color || '#0f6cbd';
    const hoverColor = tinycolor(primaryColor).darken(4).toString();
    const pressColor = tinycolor(primaryColor).darken(8).toString();
    props.setCompany(data)
    props.setCustomLightTheme({
      ...webLightTheme,
      colorBrandBackground: primaryColor,
      colorBrandBackgroundHover: hoverColor,
      colorBrandBackgroundPressed: pressColor,
      colorCompoundBrandBackground: primaryColor,
      colorCompoundBrandBackgroundHover: hoverColor,
      colorCompoundBrandBackgroundPressed: pressColor,
      colorCompoundBrandStroke: primaryColor,
      colorCompoundBrandStrokeHover: hoverColor,
      colorCompoundBrandStrokePressed: pressColor,
      colorNeutralForeground2BrandHover: primaryColor,
      colorNeutralStrokeAccessible: '#d1d1d1',
      colorNeutralStrokeAccessibleHover: '#d1d1d1',
      fontSizeBase300: '16px'
    })
    getMarkdown(data.description || '')
    setLogo('data:image/png;base64,' + data.image)
    setTitle(data.title || '')
  }

  const getCompany = () => {
    if(props.company) {
      setCompanyData(props.company)
    }
    else {
      userService.getCompanyPage(params.id).then((response) => {
        setCompanyData(response.data)
      }).catch((error)=>{
        props.setCustomLightTheme({
          ...webLightTheme,
          fontSizeBase300: '16px'
        })
        console.error(error)
        if (error && error.response && error.response.status === 404) {
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:noOrganization')
          })
        } else {
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingPage')
          })
        }
      })
    }
  }

  useEffect(()=>{
    getCompany()
  }, [])
  
  const getMarkdown = (text) => {
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(text));
    let markdown = { __html: result };
    setMarkdown(markdown)
  }

  return (
    <div className='h-100'>
      <Titlebar/>
      <div className='page-base'>
      <div className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            {props.company && props.company.image && <Image className='company-logo-large m-auto' src={props.company ? 'data:image/png;base64,' + props.company.image : ''}/>}
          </div>
          <Divider className='my-3'/>
          <h2 className='title m-0 py-3 text-center'>{title && title.length > 0 ? title : i18n.t('app:whistleblowerChannel')}</h2>
          <div className='d-flex w-100 align-items-center justify-content-center my-3' style={{flexWrap: 'wrap', gap: '2.5rem'}}>
            <div className='flex-grow-1'>
            <Button onClick={()=>history.push('/'+params.id+'/create')} className='w-100' style={{minWidth: '200px'}} appearance='primary' icon={<AddRegular/>}>{i18n.t('app:newReport')}</Button>
            </div>
            <div className='flex-grow-1'>
            <Button onClick={()=>history.push('/'+params.id+'/check')} className='w-100' style={{minWidth: '200px'}} appearance='primary' icon={<MailInboxRegular/>}>{i18n.t('app:viewReport')}</Button>
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-center'>
            <div className='flex-grow-1 markdown-frame'>
              <div className='markdown-body' dangerouslySetInnerHTML={markdown}>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false});history.push('/')}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default Main;