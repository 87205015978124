import './CreateReport.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Checkbox, Divider, Dropdown, Image, Input, Label, Spinner, Textarea, Option, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { history } from '../../_helpers';
import { ArrowLeftFilled, ImageAddFilled, ImageAddRegular, bundleIcon } from '@fluentui/react-icons';
import { Icon, IconButton } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { PitchShifter } from "soundtouchjs";
import { userService } from '../../_services/user.services';
import allLanguages from '../../_constants/languages.json'
import Moment from 'moment';

function CreateReport(props:any) {

  const params = useParams();

  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [category, setCategory] = useState("");
  const [details, setDetails] = useState("");
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [identity, setIdentity]:any = useState(i18n.t('app:sendReportAsAnonymous'));
  const [attachments, setAttachments]:any[] = useState([]);
  const [sending, setSending] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(['anonymous']);
  const [showDialog, setShowDialog] = useState(false);
  const [recording, setRecording] = useState(false);
  const [voiceFile, setVoiceFile]:any = useState(null);
  const [voiceRecording, setVoiceRecording]:any = useState(null);
  const [recordDialog, setRecordDialog]:any = useState(null);
  const [alertDialog, setAlertDialog]:any = useState({opened: false})
  const [mediaRecorder, setMediaRecorder]:any = useState(null)
  const [time, setTime]:any = useState(0)
  const [policyDialog, setPolicyDialog]:any = useState(false)
  const [markdown, setMarkdown]:any = useState('');
  const [language, setLanguage]:any = useState(props.company ? props.company.languages.find((l)=>{return l.preferred})?.name ?? '' : '');

  const _getMarkdown = (text) => {
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(text);
    let markdown = { __html: result };
    setMarkdown(markdown);
  }

  //const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const options = [
    {key: 'anonymous', text: i18n.t('app:sendReportAsAnonymous')},
    {key: 'identity', text: i18n.t('app:sendReportWithIdentity')},
  ]

  const ArrowLeft = bundleIcon(ArrowLeftFilled, ArrowLeftFilled);
  const ImageAddIcon = bundleIcon(ImageAddFilled, ImageAddRegular);

  /*
  let isBlocked = showDialog;
  let blocker = useBlocker(false);

    // Reset the blocker if the user cleans the form
    useEffect(() => {
      if (blocker.state === "blocked" && !isBlocked) {
        blocker.reset();
      }
    }, [blocker, showDialog]);
  */

  const onFileDrop = (event:any, id:string) => {
    event.stopPropagation();
    event.preventDefault();
  
    event.target.style.background = "transparent";
  
    if(event.dataTransfer.files.length > 0) {
      const files:File[] = Array.from(event.dataTransfer.files)
      let newAttachments = attachments.slice(0)
      for (let i in files) {
        if (files[i] && files[i].size >= 25000000) {
          alert("Maximum attachment allowed size: 25 Mb.")
        } else {
          newAttachments.push({name: files[i].name , file: files[i]})
        }
      }
      setAttachments(newAttachments)
    }
  }

  const _showFilePicker = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.multiple = true

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();
      const files:File[] = Array.from(event.target.files)
      let newAttachments = attachments.slice(0)
      if(files.length > 0) {
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            alert("Maximum attachment allowed size: 25 Mb.")
          } else {
            newAttachments.push({name: files[i].name , file: files[i]})
          }
        }
        setAttachments(newAttachments)
        setShowDialog(true)
      }
    }

    input.click();
  }

  const _submit = (e:any) => {
    e.preventDefault();
    if (!sending) {
      setSending(true)
      let promises:any[] = [];
      let attachmentIds:string[] = [];
      let hasError = false;

      for (let i in attachments) {
        const dropFormData = new FormData();
        dropFormData.append('attachment', attachments[i].file, attachments[i].name);
        promises.push(userService.submitAttachment(params.id, dropFormData).then((response)=>{
          attachmentIds.push(response.data.id)
        }).catch((error)=>{
          console.error(error)
          setSending(false)
          hasError = true
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorSubmittingAttachment')
          })
        }))
      }

      Promise.all(promises).then(()=>{
        if (!hasError) {
          const categoryId = (props.company && props.company.categories && props.company.categories.find((c)=>{return c.value === category})?.id)
          const data = {
            organizationCode: params.id,
            subject: subject,
            categoryId: categoryId ? Number(categoryId) : null,
            description: details,
            attachmentIds: attachmentIds,
            author: selectedOptions && selectedOptions[0] === 'identity' ? {
              name: name,
              surname: surname,
              email: email
            } : null,
            preferredLanguage: language,
          }
  
          userService.submitReport(data).then((response) => {
            setSending(false)
            history.push('/'+params.id+'/sent?code='+response.data.reportCode)
          }).catch((error)=>{
            console.error(error)
            setSending(false)
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorSubmittingReport')
            })
          })
        }
      })
    }
  }

  const confirmLeave = (e) => {
    if (showDialog) {
      const leave =  window.confirm(i18n.t('app:leavePage') || '');
      if (!leave) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  const confirmCancel = (e) => {
    if (showDialog) {
      const leave =  window.confirm(i18n.t('app:leavePage') || '');
      if (leave) {
        history.push('/'+params.id)
      }
    } else {
      history.push('/'+params.id)
    }
  }

  function startRecordingAndDownload() {
    var timeInterval = true;
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(function(stream) {
      const mediaRecorder = new MediaRecorder(stream);
      setMediaRecorder(mediaRecorder)
      const chunks:any[] = [];
      mediaRecorder.addEventListener('dataavailable', (event:any) => {
        console.log('chunk')
        chunks.push(event.data);
      });
      mediaRecorder.addEventListener('stop', () => {
        setRecording(false)
        timeInterval = false
        const blob = new Blob(chunks, { type: 'audio/webm' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        //const _audio = new Audio(downloadLink.href);
        //_audio.play();
        downloadLink.download = 'processed_audio.wav';
        downloadLink.innerText = 'Download Processed Audio';
        document.body.appendChild(downloadLink);
        changePitch(blob)
        .then((modifiedBuffer) => {
          // Do something with the modified audio buffer
          console.log('Modified audio buffer:', modifiedBuffer);
          var toWav = require('audiobuffer-to-wav')
          const wavData = toWav(modifiedBuffer)
          const blob = new Blob([wavData], {type: 'audio/wav'})
          const downloadLink = document.createElement('a');
          //downloadLink.href = URL.createObjectURL(blob);
          //downloadLink.download = 'processed_audio.wav';
          //document.body.appendChild(downloadLink);
          //downloadLink.click();
          setVoiceFile(blob);
          setVoiceRecording(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error('Error modifying pitch:', error);
        });
        //downloadLink.click();
      });
      mediaRecorder.start();
      setRecording(true)
      setVoiceFile(null);
      setVoiceRecording('')
      setTimeout(function() {
        console.log('Started recording');
        // Stop the microphone stream
        stream.getAudioTracks()[0].stop();
        mediaRecorder.stop()
        setRecording(false)
      }, 300000);
      const _addTime = (time) => {
        setTimeout(()=>{
          const newTime = time + 1000;
          setTime(newTime)
          if (timeInterval) {
            _addTime(newTime)
          }
        },1000)
      }
      _addTime(0)
    })
    .catch(function(error) {
      // Handle any errors
      console.error('Error accessing microphone:', error);
    });
  }

  const _saveVoiceRecording = () => {
    let newAttachments = attachments;
    newAttachments.push({name: i18n.t('app:recording') + '-' + new Date().getTime() + '.wav' , file: voiceFile})
    setAttachments(newAttachments)
    setRecordDialog(false)
  }

  const getCompany = () => {
    userService.getCompanyPage(params.id).then((response) => {
      var tinycolor = require("tinycolor2");
      const primaryColor = response.data.color || '#0f6cbd';
      const hoverColor = tinycolor(primaryColor).darken(4).toString();
      const pressColor = tinycolor(primaryColor).darken(8).toString();
      props.setCompany(response.data)
      props.setCustomLightTheme({
        ...webLightTheme,
        colorBrandBackground: primaryColor,
        colorBrandBackgroundHover: hoverColor,
        colorBrandBackgroundPressed: pressColor,
        colorCompoundBrandBackground: primaryColor,
        colorCompoundBrandBackgroundHover: hoverColor,
        colorCompoundBrandBackgroundPressed: pressColor,
        colorCompoundBrandStroke: primaryColor,
        colorCompoundBrandStrokeHover: hoverColor,
        colorCompoundBrandStrokePressed: pressColor,
        colorNeutralForeground2BrandHover: primaryColor,
        colorNeutralStrokeAccessible: '#d1d1d1',
        colorNeutralStrokeAccessibleHover: '#d1d1d1',
        fontSizeBase300: '16px'
      })
    }).catch((error)=>{
      props.setCustomLightTheme({
        ...webLightTheme,
        fontSizeBase300: '16px'
      })
      console.error(error)
      if (error && error.response && error.response.status === 404) {
        history.push('/')
      }
    })
  }

  useEffect(()=>{
    if(!props.company) getCompany()
  }, [])

  useEffect(()=>{
    if (props.company && props.company.policy.description) _getMarkdown(props.company.policy.description)
    if (props.company) setLanguage(props.company ? props.company.languages.find((l)=>{return l.preferred})?.name ?? '' : '')
  }, [props.company])

  return (
    <div className='h-100'>
      <Titlebar showDialog={showDialog}/>
      <div className='page-base'>
      <form onSubmit={_submit} className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5 pb-3' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            {props.company && props.company.image && <Image className='company-logo-large m-auto' src={props.company ? 'data:image/png;base64,' + props.company.image : ''}/>}
          </div>
          <Divider className='my-3'/>
          <Link to={'/'+params.id} onClick={confirmLeave}><Button className='px-0 pb-2' icon={<ArrowLeft/>} appearance='transparent' >{i18n.t('app:backToHomepage')}</Button></Link>
          <h2 className='title-small m-0 pb-3 text-center'>{i18n.t('app:newReport')}</h2>
          <div>
            <div>
              <Label required weight='semibold'>{i18n.t('app:subject')}</Label>
              <div>
                <Input value={subject} onChange={(e,data)=>{setSubject(data.value);setShowDialog(true)}} required className='w-100 mt-2'/>
              </div>
            </div>
            {props.company && props.company.categories && props.company.categories.length > 0 &&
            <div className='mt-3'>
              <Label weight='semibold'>{i18n.t('app:category')}</Label>
              <div>
                <Dropdown className='w-100 mt-2' value={category} onOptionSelect={(e,data:any)=>{setCategory(data.optionText ?? "");}}>
                  <Option key={'none'} text={''} value={''}></Option>
                  {props.company.categories.map((option:any) => (
                    <Option key={option.id} text={option.value} value={option.value}>
                      {option.value}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>}
            <div className='mt-3'>
              <Label required weight='semibold'>{i18n.t('app:details')}</Label>
              <div>
                <Textarea style={{minHeight: '200px'}} value={details} onChange={(e,data)=>{setDetails(data.value);setShowDialog(true)}} required className='w-100 mt-2 big-textarea'/>
              </div>
            </div>
            <Dialog open={recordDialog}>
              <DialogTrigger disableButtonEnhancement>
                <Button icon={<Icon iconName='Microphone'/>} className='mt-2' onClick={()=>{setTime(0);setRecordDialog(true);setVoiceFile(null);setVoiceRecording('')}}>{i18n.t('app:makeRecording')}</Button>
              </DialogTrigger>
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>{i18n.t('app:makeRecording')}</DialogTitle>
                  <DialogContent>
                    <div className='d-flex flex-column w-100 h-100 align-items-center justify-content-center'>
                      <Label className='mb-3' weight='semibold'>{recording ? i18n.t('app:clickToStopRecording') : i18n.t('app:clickToStartRecording')}</Label>
                      <Button onClick={()=> {
                        if (recording) {
                          setRecording(false);
                          mediaRecorder?.stream.getAudioTracks()[0].stop();
                          mediaRecorder?.stop();
                          setTime(0);
                        } else {setTime(0);startRecordingAndDownload()}}
                      } appearance='primary' shape='circular' style={{minWidth:'38px'}} icon={<Icon iconName={recording ? 'StopSolid' : 'Microphone'}/>}/>
                      <div style={{height: '80px'}}>
                        {voiceRecording && <audio className='mt-3' controls>
                          <source src={voiceRecording} type="audio/wav"/>
                        </audio>}
                        {!voiceRecording && recording && time > 0 ?
                          <div className='mt-3' style={{fontWeight: 600}}>{Moment(time).format('mm:ss')}</div>
                        : !voiceRecording && recording ? <div className='mt-3' style={{fontWeight: 600}}>00:00</div> : null}
                      </div>
                    </div>


                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button onClick={()=>{setTime(0);setRecordDialog(false);setRecording(false);mediaRecorder?.stop()}} appearance="secondary">{i18n.t('app:close')}</Button>
                    </DialogTrigger>
                    <Button appearance="primary" disabled={!voiceFile} onClick={()=>{setTime(0);_saveVoiceRecording();setRecording(false);setRecordDialog(false)}}>{i18n.t('app:save')}</Button>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            <div className='mt-3'>
              <Label weight='semibold'>{i18n.t('app:attachments')}</Label>
              <div
                id='attachment-area'
                className='attachment-area mt-2'
                onDragEnter={onDragEnter} 
                onDragLeave={onDragLeave}
                onDragOver={onDragOver} 
                onDrop={(e) => onFileDrop(e, 'attachment-area')}
              >
                <ImageAddIcon className='m-auto mb-1' style={{fontSize: '30px'}}/>
                <div className='text-center m-auto mt-1'>
                  <span className='me-1 file-selector' onClick={()=>{_showFilePicker()}}>{i18n.t('app:selectFile')}</span>
                  <span >{i18n.t('app:orDragAndDrop')}</span>
                </div>
              </div>
            </div>
            <div className='mt-3'>
              {attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
                <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.')[item.name.split('.').length -1], size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                <span className='me-2' title={item.name}>{item.name}</span>
                <IconButton className='ms-auto' iconProps={{iconName: 'Delete'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                  e.stopPropagation();
                  setAttachments(attachments.filter((a:any)=>{return a.name !== item.name}))
                }}/>
              </div>})}
            </div>
            <div className='mt-3'>
              <Label weight='semibold'>{i18n.t('app:preferredLanguage')}</Label>
              <div>
                <Dropdown className='w-100 mt-2' value={allLanguages[language.toLowerCase()]?.nativeName || ''} selectedOptions={[allLanguages[language.toLowerCase()]?.nativeName]} onOptionSelect={(e,data:any)=>{setLanguage(data.optionText ?? "")}}>
                  {props.company?.languages.sort((a,b) => {return a.name.localeCompare(b.name)}).map((option:any) => (
                    <Option key={option.id} text={option.name} value={allLanguages[option.name.toLowerCase()]?.nativeName}>
                      {allLanguages[option.name.toLowerCase()]?.nativeName}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>
            <div className='mt-3'>
              <Label weight='semibold'>{i18n.t('app:identity')}</Label>
              <div>
                <Dropdown className='w-100 mt-2' value={identity} selectedOptions={selectedOptions} onOptionSelect={(e,data:any)=>{setIdentity(data.optionText ?? ""); setSelectedOptions(data.selectedOptions)}}>
                  {options.map((option:any) => (
                    <Option key={option.key} text={option.text} value={option.key}>
                      {option.text}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>
            {selectedOptions && selectedOptions[0] === 'identity' && <>
              <div className='mt-3'>
                <Label required weight='semibold'>{i18n.t('app:firstName')}</Label>
                <div>
                  <Input value={name} onChange={(e,data:any)=>{setName(data.value);setShowDialog(true)}} required className='w-100 mt-2'/>
                </div>
              </div>
              <div className='mt-3'>
                <Label required weight='semibold'>{i18n.t('app:lastName')}</Label>
                <div>
                  <Input value={surname} onChange={(e,data:any)=>{setSurname(data.value);setShowDialog(true)}} required className='w-100 mt-2'/>
                </div>
              </div>
              <div className='mt-3'>
                <Label required weight='semibold'>{i18n.t('app:email')}</Label>
                <div>
                  <Input type='email' value={email} onChange={(e,data:any)=>{setEmail(data.value);setShowDialog(true)}} required className='w-100 mt-2'/>
                </div>
              </div>
            </> }
            <div className='mt-3' style={{left: '-8px', position: 'relative'}}>
              <div>
                <Checkbox checked={confirmTerms} onChange={(e, data:any)=>{setConfirmTerms(data.checked);setShowDialog(true)}} style={{color: 'black'}} required label={<><span>{i18n.t('app:confirmTerms')}</span>{props.company && <a className='ms-1' onClick={(e)=>{if (props.company && props.company.policy.description) {e.preventDefault(); setPolicyDialog(true)} }} href={props.company.policy.link} target='_blank'>{i18n.t('app:informationData')}</a>}.</>} />
              </div>
            </div>
            <div className='d-flex w-100 ms-auto align-items-center mt-3' style={{flexWrap: 'wrap', gap: '16px', maxWidth: '500px'}}>
              <div className='flex-grow-1'>
              <Button disabled={sending} onClick={confirmCancel} className='w-100' style={{minWidth: '200px'}}>{i18n.t('app:cancel')}</Button>
              </div>
              <div className='flex-grow-1'>
              <Button disabled={sending} type='submit' className='flex-grow-1 w-100' style={{minWidth: '200px'}} appearance='primary'>{sending ? <Spinner size='tiny'/> : i18n.t('app:send')}</Button>
              </div>
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-center'>
          </div>
        </div>
      </form>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {props.company && <Dialog open={policyDialog && props.company}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{i18n.t('app:policy')}</DialogTitle>
            <DialogContent>
              {props.company.policy.link && <a href={props.company.policy.link}>{props.company.policy.link}</a>}
              <div className='mt-2'>
                <div className='flex-grow-1 markdown-frame' style={{minHeight: '200px', maxHeight: '500px', overflowY: 'auto', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                  <div className='markdown-body' dangerouslySetInnerHTML={markdown}>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setPolicyDialog(false)}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>}
    </div>
  );
}

const onDragEnter = (event: any) => {
  event.target.style.background = "#C7E0F4";
}

const onDragLeave = (event: any) => {
  event.target.style.background = "transparent";
}

const onDragOver = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
}

export default CreateReport;

function changePitch(blob) {
  return blob.arrayBuffer().then(arrayBuffer => {
    // Create a new AudioContext
    const _window:any = window;
    const audioContext:AudioContext = new (_window.AudioContext || _window.webkitAudioContext)();

    return new Promise((resolve, reject) => {
      // Decode the audio data from the ArrayBuffer
      audioContext.decodeAudioData(arrayBuffer, (decodedData) => {
        const source = audioContext.createBufferSource();
        source.buffer = decodedData;

        // Create a pitch shifter using the AudioContext and OfflineAudioContext
        const offlineAudioContext = new OfflineAudioContext(
          decodedData.numberOfChannels,
          decodedData.length,
          decodedData.sampleRate
        );

        // Create an AudioBufferSourceNode from the decoded audio data
        const audioBufferSource = offlineAudioContext.createBufferSource();
        audioBufferSource.buffer = decodedData;

        var shifter = new PitchShifter(offlineAudioContext, decodedData, 1024);
        shifter.on('play', (detail) => {
          // do something with detail.timePlayed;
          // do something with detail.formattedTimePlayed;
          // do something with detail.percentagePlayed
        });
        shifter.tempo = 1;
        shifter.pitch = .8;
        
        let filterNode = offlineAudioContext.createBiquadFilter();
        filterNode.type = "lowpass";
        filterNode.frequency.value = Math.random() * 2000;

        audioBufferSource.connect(shifter._node)
        shifter._node.connect(offlineAudioContext.destination);
        filterNode.connect(offlineAudioContext.destination)

        // Start rendering the modified audio in the OfflineAudioContext
        audioBufferSource.start();
        offlineAudioContext.startRendering();

        // When rendering is complete, resolve the Promise with the modified audio data
        offlineAudioContext.oncomplete = (event) => {
          const modifiedBuffer = event.renderedBuffer;
          resolve(modifiedBuffer);
        };
      }, (error) => {
        reject(error);
      });
    });
  })

}