import './App.scss';
import { FluentProvider, Theme, webLightTheme } from '@fluentui/react-components';
import { Route, Router, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements, useParams } from 'react-router-dom';
import Main from './_pages/Main/Main';
import { withTranslation } from 'react-i18next';
import { history } from './_helpers';
import { useState } from 'react';
import CreateReport from './_pages/CreateReport/CreateReport';
import NotFound from './_pages/NotFound/NotFound';
import CheckReport from './_pages/CheckReport/CheckReport';
import Report from './_pages/Report/Report';
import ReportSent from './_pages/ReportSent/ReportSent';

function App(props:any) {

  const [currentURL, setCurrentURL] = useState(true)
  const [company, setCompany]:any = useState();

  history.listen(() => {
    setCurrentURL(!currentURL)
  });

  const [customLightTheme, setCustomLightTheme]:any = useState({
    ...webLightTheme,
    colorBrandBackground: 'transparent',
    colorBrandBackgroundHover: 'transparent',
    colorBrandBackgroundPressed: 'transparent',
    colorCompoundBrandBackground: 'transparent',
    colorCompoundBrandBackgroundHover: 'transparent',
    colorCompoundBrandBackgroundPressed: 'transparent',
    colorCompoundBrandStroke: 'transparent',
    colorCompoundBrandStrokeHover: 'transparent',
    colorCompoundBrandStrokePressed: 'transparent',
    colorNeutralForeground2BrandHover: 'transparent',
    colorNeutralStrokeAccessible: '#d1d1d1',
    colorNeutralStrokeAccessibleHover: '#d1d1d1',
    fontSizeBase300: '16px'
  });

  let router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<NotFound page="notfound" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/> } />,
        <Route path="/404" element={<NotFound page="notfound" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />,
        <Route path="/:id" element={<Main page="main" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />,
        <Route path="/:id/create" element={<CreateReport page="create" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />,
        <Route path="/:id/check" element={<CheckReport page="check" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />,
        <Route path="/:id/sent" element={<ReportSent page="sent" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />,
        <Route path="/:id/report" element={<Report page="report" setCustomLightTheme={setCustomLightTheme} company={company} setCompany={setCompany}/>} />
      ]
    )
  );

  return (
    <FluentProvider style={{width: '100vw', height: '100vh'}} theme={customLightTheme}>
      {/*<Router location={history.location} navigator={history}>
        <Routes>
          <Route path="/404" element={<NotFound page="notfound" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/:id" element={<Main page="main" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/:id/create" element={<CreateReport page="create" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/:id/check" element={<CheckReport page="check" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/:id/sent" element={<ReportSent page="sent" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/:id/report/:reportId" element={<Report page="report" setCustomLightTheme={setCustomLightTheme}/>} />
          <Route path="/" element={<NotFound page="notfound" setCustomLightTheme={setCustomLightTheme}/>} />
        </Routes>
      </Router>*/}
      <RouterProvider router={router} />
    </FluentProvider>
  );
}

const connectedApp = withTranslation()(App);
export { connectedApp as App }; 