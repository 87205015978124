import { Link, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import './Titlebar.scss';
import { history } from '../../_helpers';
import i18n from '../../i18n';
import { useParams } from 'react-router-dom';
import { GlobeRegular } from '@fluentui/react-icons';

function Titlebar(props:any) {

    const params = useParams();

    const setLanguage = (lng:string) => {
        i18n.changeLanguage(lng)
    }

    const languages = {
        en: 'English',
        es: 'Español'
    }

    const handleLogoClick = (e) => {
        if(params && params.id) {
            if (props.showDialog) {
                const leave =  window.confirm(i18n.t('app:leavePage') || '');
                if (leave) {
                    history.push("/"+params.id)
                }
            } else {
                history.push("/"+params.id)
            }
        }

    }

    return (
        <div className="Titlebar d-flex">
            <div className="d-flex title align-items-center me-3" style={{minWidth: '25%'}}>
                <img src={ process.env.PUBLIC_URL + "/logo192.png" } className="img-fluid mx-3" alt="Synergy Home logo" />
                <div className='d-sm-block' style={{display: 'none'}}>
                <Link onClick={handleLogoClick}>
                    Whistleblower Channel
                </Link>
                </div>
            </div>
            <div className='d-flex align-items-center ms-auto pe-2'>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                    <MenuButton className='language-button' icon={<GlobeRegular/>} appearance='transparent'>{languages[i18n.language]}</MenuButton>
                    </MenuTrigger>

                    <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={()=>{setLanguage('en')}}>English</MenuItem>
                        <MenuItem onClick={()=>{setLanguage('es')}}>Español</MenuItem>
                    </MenuList>
                    </MenuPopover>
                </Menu>
                <a className='d-sm-flex align-items-center ms-3' href='https://www.synergyos.com' style={{display: 'none'}}><img src="/img/synergy_logo_white.png" height="14px" alt="Synergy Inc. logo" /></a>
            </div>
        </div>
    );
}

export default Titlebar;