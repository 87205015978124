import './ReportSent.scss';
import Titlebar from '../../_components/Titlebar/Titlebar';
import { Button, Divider, Image, Spinner, Text, webLightTheme } from '@fluentui/react-components';
import i18n from '../../i18n';
import { useParams } from 'react-router-dom';
import { history } from '../../_helpers';
import { useEffect, useState } from 'react';
import { ArrowDownloadRegular, CopyRegular, bundleIcon } from '@fluentui/react-icons';
import { userService } from '../../_services/user.services';

function ReportSent(props:any) {

  const params = useParams();

  const [code, setCode] = useState('XXXX-XXXX-XXXX-XXXX');
  const [action, setAction] = useState('');

  const CopyIcon = bundleIcon(CopyRegular, CopyRegular);
  const DownloadIcon = bundleIcon(ArrowDownloadRegular, ArrowDownloadRegular);

  
  const _submit = (e:any) => {
    e.preventDefault();
    history.push('/'+params.id)
  }

  const _copy = () => {
    let spanElement = document.createElement("span");
    spanElement.innerText = code;
    spanElement.style.fontFamily = '';
    spanElement.style.fontSize = 'inherit';
    document.body.appendChild(spanElement);

    const range = document.createRange();
    range.selectNode(spanElement);
    const selection:any = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy')
    document.body.removeChild(spanElement);
    setAction('copied')
    setTimeout(()=>{
      setAction('')
    },2000)
  }
  

  const _download = () => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(`\nReport access code:\n\n${code}\n\nStore this file safely so you don't lose access to your report.`));
    element.setAttribute('download', 'SynergyOS Report.txt');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    setAction('downloaded')
    setTimeout(()=>{
      setAction('')
    },2000)
  }

  const getCompany = () => {
    userService.getCompanyPage(params.id).then((response) => {
      var tinycolor = require("tinycolor2");
      const primaryColor = response.data.color || '#0f6cbd';
      const hoverColor = tinycolor(primaryColor).darken(4).toString();
      const pressColor = tinycolor(primaryColor).darken(8).toString();
      props.setCompany(response.data)
      props.setCustomLightTheme({
        ...webLightTheme,
        colorBrandBackground: primaryColor,
        colorBrandBackgroundHover: hoverColor,
        colorBrandBackgroundPressed: pressColor,
        colorCompoundBrandBackground: primaryColor,
        colorCompoundBrandBackgroundHover: hoverColor,
        colorCompoundBrandBackgroundPressed: pressColor,
        colorCompoundBrandStroke: primaryColor,
        colorCompoundBrandStrokeHover: hoverColor,
        colorCompoundBrandStrokePressed: pressColor,
        colorNeutralForeground2BrandHover: primaryColor,
        colorNeutralStrokeAccessible: '#d1d1d1',
        colorNeutralStrokeAccessibleHover: '#d1d1d1',
        fontSizeBase300: '16px'
      })
    }).catch((error)=>{
      props.setCustomLightTheme({
        ...webLightTheme,
        fontSizeBase300: '16px'
      })
      console.error(error)
      if (error && error.response && error.response.status === 404) {
        history.push('/')
      }
    })
  }

  useEffect(()=>{
    if(!props.company) getCompany()
  }, [])

  useEffect(()=>{
    const queryParams = new URLSearchParams(window.location.search)
    const reportCode = queryParams.get('code')
    if (reportCode) {
      setCode(reportCode)
      history.replace('/'+params.id+'/sent')
    }
  }, [])

  return (
    <div className='h-100'>
      <Titlebar/>
      <div className='page-base'>
      <form onSubmit={_submit} className='h-100 mx-auto py-3' style={{maxWidth: '800px', width: '100%', backgroundColor: 'white'}}>
        <div className='px-3 px-sm-5 pb-3' style={{backgroundColor: 'white'}}>
          <div className='d-flex' style={{minHeight: '120px'}}>
            {props.company && props.company.image && <Image className='company-logo-large m-auto' src={props.company ? 'data:image/png;base64,' + props.company.image : ''}/>}
          </div>
          <Divider className='my-3'/>
          <h2 className='title-small m-0 pb-3 text-center'>{i18n.t('app:reportSent')}</h2>
          <div className='text-center p-3' style={{borderRadius: '4px', backgroundColor: 'rgb(250, 249, 248)'}}>
            <Text>
              {i18n.t('app:downloadOrCopyCode')}
            </Text>
            <div>
            <div className='code-wrapper mt-3'>
              <div className='d-flex' style={{backgroundColor: 'white', height: '48px', borderRadius: '4px'}}>
              <Text className='m-auto px-3'>{code}</Text>
              </div>
            </div>
            <div className='mt-3'>
              <Button onClick={_copy} icon={<CopyIcon/>} className='mx-2' style={{minWidth: '120px'}} appearance='primary'>{i18n.t('app:copy')}</Button>
              <Button onClick={_download} icon={<DownloadIcon/>} className='mx-2' style={{minWidth: '120px'}} appearance='primary'>{i18n.t('app:download')}</Button>
            </div>
            {action &&<div className='mt-2'>{ <span>{i18n.t('app:'+action)}!</span>}</div>}
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-center'>
            <div className='d-flex w-100 ms-auto align-items-center justify-content-center mt-3'>
              <Button type='submit' className='w-100' style={{maxWidth: '200px'}} >{i18n.t('app:backToHomepage')}</Button>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  );
}

export default ReportSent;